import React, { useEffect, useState } from 'react'
import { format, differenceInDays, addDays } from 'date-fns'
import humanizeString from 'humanize-string'
import axios from 'lib/axios'

import { formatMoney } from 'lib/numbers'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  Checkbox,
  Stack,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Tooltip
} from '@chakra-ui/react'
import { CashoutFragment } from 'graphql/generated'
import { hasCapability } from 'lib/admin-perms'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { parseDate } from 'lib/date-time'

interface CashoutStatusSummary {
  current_status: string
  next_status: string
  accomplished_statuses: Array<string>
  cannot_advance_reasons?: Array<string>
}
export default function Cashouts({ rental }: { rental: CashoutFragment }) {
  const [errorText, setErrorText] = useState<string>('')
  const [statusSummary, setStatusSummary] = useState<CashoutStatusSummary | null>(null)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const admin = useSelector<StoreState, any>((state) => state.admin)
  const cashout = rental.cashout
  const cashoutId = cashout?.id
  const canManageCashouts = hasCapability(admin, 'cashout_administrator')
  useEffect(() => {
    async function fetchCashout() {
      try {
        const { data } = await axios.get(`/admin/cashouts/${cashoutId}/next_status`)
        setStatusSummary(data)
      } catch (err: any) {
        setErrorText(err.message)
      }
    }
    if (cashoutId) {
      fetchCashout()
    }
  }, [cashoutId, isSaving])

  if (!rental || !cashoutId || !canManageCashouts) {
    return null
  }

  const {
    current_status: currentStatus,
    next_status: nextStatus,
    accomplished_statuses: accomplishedStatuses,
    cannot_advance_reasons: cannotAdvanceReasons
  } = statusSummary || {}

  // Helpers to have FE dumb and have BE dictate checkbox ability
  // Only works with a linear machine!
  const statuses = [
    'utilities_finalized',
    'ledger_finalized',
    'adjustments_finalized',
    'cashed_out'
  ] as const

  const hasStatusHappened = (status: (typeof statuses)[number]): boolean => {
    return !!accomplishedStatuses?.includes(status)
  }

  const isEnabled = (checkBoxStatus: (typeof statuses)[number]): boolean => {
    if (isSaving) {
      return false
    }

    if (hasStatusHappened(checkBoxStatus)) {
      return false
    }

    if (checkBoxStatus !== nextStatus) {
      return false
    }

    if (checkBoxStatus === 'adjustments_finalized') {
      return false // have to do this via the finalize adjustments button or automatic webhook
    }

    return (cannotAdvanceReasons?.length ?? 0) === 0
  }

  const handleChecked = async (checkboxStatus: string, e: any) => {
    if (!e.target.checked) {
      return
    }

    try {
      setIsSaving(true)
      await axios.post(`/admin/cashouts/${cashoutId}/advance`, {
        status: checkboxStatus
      })
    } catch (err: any) {
      setErrorText(err.message)
    } finally {
      setIsSaving(false)
    }
  }

  const tooltipForStatus = (status: (typeof statuses)[number]) => {
    if (status !== nextStatus || !cannotAdvanceReasons) {
      return null
    }

    return (
      <ul>
        {cannotAdvanceReasons.map((reason) => {
          return <li key={reason}>{reason}</li>
        })}
      </ul>
    )
  }

  const createCashoutAlert = () => {
    if (!nextStatus || currentStatus === nextStatus) {
      return null
    }

    if (cannotAdvanceReasons && cannotAdvanceReasons.length > 0) {
      return (
        <Alert status='warning'>
          <AlertIcon />
          <AlertTitle minW='fit-content' pr={1}>
            Warning!
          </AlertTitle>
          <AlertDescription>
            This cashout cannot move to {humanizeString(nextStatus)} because of the following
            reasons: {cannotAdvanceReasons.join(', ')}
          </AlertDescription>
        </Alert>
      )
    }

    return (
      <Alert status='success'>
        <AlertIcon />
        <AlertTitle minW='fit-content' pr={1}>
          Attention!
        </AlertTitle>
        <AlertDescription>
          This cashout is ready to move to {humanizeString(nextStatus)}.
        </AlertDescription>
      </Alert>
    )
  }

  const moveOutDate = rental.move_out_date
  const chargebackDeadline = rental.rent_roll?.chargeback_deadline
  const cashoutDeadline = rental.rent_roll?.cashout_deadline
  let chargebackDaysLeft = null
  let cashoutDaysLeft = null

  if (moveOutDate) {
    if (chargebackDeadline) {
      chargebackDaysLeft = differenceInDays(
        addDays(parseDate(moveOutDate), chargebackDeadline),
        new Date()
      )
    }
    if (cashoutDeadline) {
      cashoutDaysLeft = differenceInDays(
        addDays(parseDate(moveOutDate), cashoutDeadline),
        new Date()
      )
    }
  }

  const badgeContent = (type: string, daysLeft: number | null) => {
    const formatFunc = (input: string) => {
      return input
    }
    if (daysLeft) {
      if (daysLeft < 0) {
        return mapContentToBadge(`${type}: ${daysLeft} days past`, {
          colorOverride: 'Red',
          formatFn: formatFunc
        })
      } else if (daysLeft === 0) {
        return mapContentToBadge(`${type}: Due today`, {
          colorOverride: 'Orange',
          formatFn: formatFunc
        })
      } else if (daysLeft === 1) {
        return mapContentToBadge(`${type}: ${daysLeft} day left`, {
          colorOverride: 'Yellow',
          formatFn: formatFunc
        })
      } else if (daysLeft <= 3) {
        return mapContentToBadge(`${type}: ${daysLeft} days left`, {
          colorOverride: 'Yellow',
          formatFn: formatFunc
        })
      }
      return mapContentToBadge(`${type}: ${daysLeft} days left`, {
        formatFn: formatFunc
      })
    }
    return
  }

  return (
    <ContentSectionCard
      padding
      collapsable={false}
      title={
        <span>
          {'Cashout'} {badgeContent('Chargeback', chargebackDaysLeft)}{' '}
          {badgeContent('Cashout', cashoutDaysLeft)}
        </span>
      }
      action={
        <Button as={ReactRouterLink} to={`/rent-roll/${rental.id}/cashout`}>
          View Cashout
        </Button>
      }
    >
      <Stack>
        {cashout.cashed_out_at && (
          <Text>
            A cashout of {formatMoney(cashout.paid_out_amount, 2, '$')} for{' '}
            {cashout.user.first_name} {cashout.user.last_name} is{' '}
            <Text as='b'>{humanizeString(cashout.status)}</Text>.
          </Text>
        )}
        {createCashoutAlert()}
        {errorText && (
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle>Ouch!</AlertTitle>
            <AlertDescription>{errorText}</AlertDescription>
          </Alert>
        )}
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Action</Th>
                <Th>Admin</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {statuses.map((status) => {
                return (
                  <Tr key={status}>
                    <Td>
                      <Tooltip shouldWrapChildren label={tooltipForStatus(status)}>
                        <Checkbox
                          isDisabled={!isEnabled(status)}
                          isChecked={hasStatusHappened(status)}
                          onChange={(e) => {
                            return handleChecked(status, e)
                          }}
                        >
                          <Text>{humanizeString(status)}</Text>
                        </Checkbox>
                      </Tooltip>
                    </Td>
                    <Td>
                      {cashout[`${status}_by_admin`]?.first_name}{' '}
                      {cashout[`${status}_by_admin`]?.last_name}
                    </Td>
                    <Td>
                      {cashout[`${status}_at`] && (
                        <span>{format(new Date(cashout[`${status}_at`]), 'PPppp')}</span>
                      )}
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </ContentSectionCard>
  )
}
