import React from 'react'
import { useToast, Tabs, TabList, TabPanels, TabPanel, Box, Divider, Tab } from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { PencilIcon, DocumentIcon } from '@heroicons/react/20/solid'
import { BuyersInspection, BuyersInspectionApiResponse } from '@homevest/types/buyers-inspections'

import axios from 'lib/axios'
import { Sidepeek } from 'ui'
import { BuyersInspectionFormData, validateBuyersInspectionForm } from 'lib/buyers-inspections'
import BuyersInspectionForm from './BuyersInspectionForm'
import InspectionDocuments from '../InspectionDocuments'

const EditBuyersInspectionSidepeek = ({
  inspection,
  portfolioHomeId,
  isOpen,
  onSubmit,
  onClose
}: {
  inspection?: BuyersInspectionApiResponse | null
  portfolioHomeId: string
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}) => {
  const toast = useToast()
  const { mutate: editBuyersInspection, isLoading } = useMutation({
    mutationFn: async (data: BuyersInspectionFormData) => {
      return axios.put<BuyersInspection>(`/admin/buyers_inspections/${inspection?.id}`, data)
    },
    onSuccess: () => {
      toast({
        title: "Buyer's Inspection Edited",
        status: 'success',
        duration: 5000,
        position: 'bottom-right'
      })
      onSubmit()
      onClose()
    },
    onError: (error: any) => {
      toast({
        title: "Error Editing Buyer's Inspection",
        description: error?.message || 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        position: 'bottom-right'
      })
    }
  })

  if (!inspection) {
    return null
  }

  return (
    <Sidepeek
      isOpen={isOpen}
      onClose={onClose}
      title={"Buyer's Inspection"}
      closeOnOverlayClick={false}
    >
      <Tabs isFitted variant='solid-rounded' colorScheme='teal'>
        <TabList mb='1em'>
          <Tab>{<PencilIcon className='mr-1 h-4 w-4' />} Edit</Tab>
          <Tab>{<DocumentIcon className='mr-1 h-4 w-4' />} Documents</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box mb={4}>
              <Divider borderColor='gray.300' />
            </Box>
            <BuyersInspectionForm
              initialData={inspection}
              isLoading={isLoading}
              portfolioHomeId={portfolioHomeId}
              onSubmit={(data) => editBuyersInspection(data)}
              validator={validateBuyersInspectionForm}
            />
          </TabPanel>
          <TabPanel>
            <Divider borderColor='gray.300' mb={4} />
            <InspectionDocuments inspectionId={inspection.id} resourceType='buyers_inspection' />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Sidepeek>
  )
}

export default EditBuyersInspectionSidepeek
