import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import moment from 'moment'
import isDateBeforeRentRollLockDate from 'lib/utils'
import { rentRollLedgerLockDate } from 'lib/config'
import { Button, Input, ErrorText, TextArea } from 'components/Toolkit'
import { useUpupInsertRentalCreditMutation } from 'graphql/generated'
import { rentalCredits } from '@homevest/utils'
import LiabilityTypesDropdown from '../Dropdowns/LiabilityTypesDropdown'
import { RentalDetailsContext } from '../RentalDetailsContext'

const IssueCreditForm: React.FC<
  React.PropsWithChildren<{
    onSubmit: () => void
    rentalId: string
  }>
> = ({ onSubmit, rentalId }) => {
  const firstOfNextMonth = moment().add(1, 'months').startOf('month')
  const [price, setPrice] = useState('')
  const [note, setNote] = useState('')
  const [date, setDate] = useState(firstOfNextMonth.format('YYYY-MM-DD'))
  const [isMoveInCredit, setIsMoveInCredit] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [liabilityType, setLiabilityType] = useState<{ id: string }>()
  const [{ fetching, error }, createRentalCredit] = useUpupInsertRentalCreditMutation()
  let { rentalData, liabilityTypes } = useContext(RentalDetailsContext)

  const isLedgerLocked = isDateBeforeRentRollLockDate(date)
  const rentLiabilityType = liabilityTypes.find((lt) => lt.name === 'Rent')

  const isDateValid = () => {
    if (!date) {
      return false
    }
    if (!moment(date).isValid()) {
      return false
    }
    return (
      moment(date).isSameOrAfter(firstOfNextMonth) ||
      moment(date).isSame(rentalData?.occupancy_date)
    )
  }

  const isValid =
    isDateValid() &&
    price &&
    !Number.isNaN(price) &&
    note &&
    note.trim() &&
    liabilityType?.id &&
    !isLedgerLocked

  const handleSubmit = async () => {
    if (!isValid) {
      return
    }

    setIsSaving(true)

    const result = await createRentalCredit({
      amount: Number(price),
      date,
      note: note.trim(),
      rental_id: rentalId,
      type: rentalCredits.RENTAL_CREDIT_TYPES.EXCHANGE,
      liability_type_id: liabilityType?.id,
      move_in_credit: isMoveInCredit
    })

    setIsSaving(false)

    if (result.error === undefined) {
      setPrice('')
      setDate('')
      setNote('')
      setLiabilityType(undefined)
      onSubmit()
    }
  }

  const toggleIsMoveInCredit = () => {
    if (isMoveInCredit) {
      setNote('')
      setIsMoveInCredit(false)
    } else {
      setLiabilityType({ id: rentLiabilityType?.id })
      setNote('Move-In Credit')
      setIsMoveInCredit(true)
    }
  }

  return (
    <>
      <React.Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              padding: '6px',
              justifyContent: 'center',
              marginTop: '200px'
            }}
          >
            <Spinner color='primary' />
          </div>
        }
      >
        <Input
          label='Is this a move-in credit?'
          type='checkbox'
          value={isMoveInCredit}
          onChange={toggleIsMoveInCredit}
          hasError={false}
        />
        <Input
          label='Price'
          type='number'
          onChange={setPrice}
          value={price}
          placeholder='Enter the amount of the credit'
          required
          min='0'
          hasError={Number(price) <= 0}
          disabled={isSaving || fetching}
        />
        <Input
          label='Date to credit - usually the first of the next month'
          type='date'
          onChange={setDate}
          value={date}
          placeholder='Usually the first of the next month'
          required
          disabled={fetching || isSaving}
          hasError={!date}
        />
        <LiabilityTypesDropdown
          disabled={fetching || isSaving}
          onSelect={setLiabilityType}
          value={liabilityType}
        />
        <TextArea
          label='Note'
          type='textarea'
          rows={5}
          onChange={setNote}
          value={note}
          placeholder='Enter the reason for giving the credit'
          required
          disabled={fetching || isSaving}
          hasError={!note}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            isSecondary={false}
            style={{
              marginTop: '2rem',
              marginBottom: '2rem'
            }}
            onClick={handleSubmit}
            disabled={!isValid || fetching || isSaving}
          >
            Issue Rental Credit
          </Button>
          {error !== undefined && <ErrorText>{error.message}</ErrorText>}
          {isLedgerLocked && (
            <ErrorText>
              Updates to rent-roll before {rentRollLedgerLockDate} is not allowed. FinOps is doing a
              Rent Roll Audit. Please reach out to: Sam Maniscalco and/or Jered Griffith for more
              details.
            </ErrorText>
          )}
        </div>
      </React.Suspense>
    </>
  )
}

IssueCreditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  rentalId: PropTypes.string.isRequired
}

export default IssueCreditForm
