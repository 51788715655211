import React from 'react'
import { FormControl, FormLabel, FormErrorMessage, Input } from '@chakra-ui/react'
import { formatUTCDate, formatForDatetimeInput } from 'lib/date-time'

export type FieldProps<T> = {
  field: keyof T
  label: string
  isRequired: boolean
  isDisabled?: boolean
  errors: Record<keyof T, string>
}

export type BaseFormFieldProps<T> = FieldProps<T> & {
  children: React.ReactNode
}

export type DateFormFieldProps<T> = FieldProps<T> & {
  data: T
  setData: (data: T) => void
}

export const BaseFormField = <T,>({
  field,
  label,
  isRequired,
  isDisabled,
  children,
  errors
}: BaseFormFieldProps<T>) => (
  <FormControl isRequired={isRequired} isInvalid={!!errors[field]} isDisabled={isDisabled}>
    <FormLabel>{label}</FormLabel>
    {children}
    <FormErrorMessage>{errors[field]}</FormErrorMessage>
  </FormControl>
)

export const DateFormField = <T,>({
  field,
  label,
  isRequired,
  isDisabled,
  errors,
  data,
  setData
}: DateFormFieldProps<T>) => (
  <BaseFormField
    field={field}
    label={label}
    isRequired={isRequired}
    isDisabled={isDisabled}
    errors={errors}
  >
    <Input
      value={formatUTCDate(data[field] as Date)}
      onChange={(e) =>
        setData({
          ...data,
          [field]: e.target.value ? new Date(e.target.value) : null
        })
      }
      type='date'
    />
  </BaseFormField>
)

export const DateTimeFormField = <T,>({
  field,
  label,
  isRequired,
  isDisabled,
  errors,
  data,
  setData
}: DateFormFieldProps<T>) => (
  <BaseFormField
    field={field}
    label={label}
    isRequired={isRequired}
    isDisabled={isDisabled}
    errors={errors}
  >
    <Input
      value={formatForDatetimeInput(data[field] as Date | null)}
      onChange={(e) =>
        setData({
          ...data,
          [field]: e.target.value ? new Date(e.target.value) : null
        })
      }
      type='datetime-local'
    />
  </BaseFormField>
)
