"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CAPABILITY_TYPES = void 0;
exports.CAPABILITY_TYPES = {
    ACCOUNT_CREATOR: 'account_creator',
    AGENT_APPROVER: 'agent_approver',
    AGENT_SALES: 'agent_sales',
    AGREEMENT_SIGNER: 'agreement_signer',
    APPLICATION_REVIEWER: 'application_reviewer',
    APPLICATION_UNDERWRITER: 'application_underwriter',
    APPLICATION_VIEWER: 'application_viewer',
    CASHOUTS_ADMIN: 'cashouts_admin',
    CONSTRUCTION_PROJECT_ASSIGNEE: 'construction_project_assignee',
    CONSTRUCTION_PROJECT_MANAGER: 'construction_project_manager',
    CLOSER: 'closer',
    CUSTOMER_CHARGER: 'customer_charger',
    DAGGER: 'dagger',
    DELINQUENCY_MANAGER: 'delinquency_manager',
    DISPATCHER: 'dispatcher',
    ENGINEER: 'engineer',
    ESCALATION_TRIAGER: 'escalation_triager',
    IC_DECIDER: 'ic_decider',
    IC_EXECUTIVE_DECIDER: 'ic_executive_decider',
    IMPERSONATOR: 'impersonator',
    INCOME_REVIEWER: 'income_reviewer',
    INITIAL_PAYMENTS_CHARGER: 'initial_payments_charger',
    LEAD_MANAGER_TOOL_USER: 'lead_manager_tool_user',
    LEDGER_FINAL_LIABILITY_DATE_EDITOR: 'ledger_final_liability_date_editor',
    LEDGER_FINAL_LIABILITY_DATE_SETTER: 'ledger_final_liability_date_setter',
    LEDGER_LIABILITY_CREATOR: 'ledger_liability_creator',
    LEDGER_MOVE_OUT_DATE_EDITOR: 'ledger_move_out_date_editor',
    LEDGER_MOVE_OUT_DATE_SETTER: 'ledger_move_out_date_setter',
    LEDGER_RENT_CREDIT_ISSUER: 'ledger_rent_credit_issuer',
    LEDGER_RENTAL_AGREEMENT_HISTORY_CREATOR: 'ledger_rental_agreement_history_creator',
    LEDGER_RENTAL_AGREEMENT_HISTORY_EDITOR: 'ledger_rental_agreement_history_editor',
    LEDGER_SUPER_EDITOR: 'ledger_super_editor',
    LEDGER_VIEWER: 'ledger_viewer',
    MLS_PHOTO_MANAGER: 'mls_photo_manager',
    MLS_UPLOADER: 'mls_uploader',
    MOVE_IN_CHARGERS: 'move_in_chargers',
    OCCUPANCY_INSPECTION_ASSIGNEE: 'occupancy_inspection_assignee',
    PAYMENT_REFUNDER: 'payment_refunder',
    PERMISSION_EDITOR: 'permission_editor',
    PROGRAM_INTRODUCER: 'program_introducer',
    PROPERTY_PRICER: 'property_pricer',
    REMATCHER: 'rematcher',
    RENEWAL_CREATOR: 'renewal_creator',
    TASK_SELECTOR: 'task_selector',
    TASK_TYPE_EDITOR: 'task_type_editor',
    TOUR_CONFIRMER: 'tour_confirmer',
    USER_ACCOUNT_CREATOR: 'user_account_creator',
    USER_MANAGER: 'user_manager',
    USER_DOC_VIEWER: 'user_doc_viewer',
    USER_PLAID_DOC_VIEWER: 'user_plaid_doc_viewer',
    USER_INCOME_EDITOR: 'user_income_editor',
    UTILITIES_CONFIGURATION_EDITOR: 'utilities_configuration_editor'
};
