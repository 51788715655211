import React, { isValidElement } from 'react'
import {
  Text,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody
} from '@chakra-ui/react'

type SidepeekPropTypes = {
  isOpen: boolean
  onClose: () => void
  title?: React.ReactNode
  subtitle?: React.ReactNode
  closeOnOverlayClick?: boolean
}

const Sidepeek: React.FC<React.PropsWithChildren<SidepeekPropTypes>> = ({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  closeOnOverlayClick = true
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size='lg' closeOnOverlayClick={closeOnOverlayClick}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          {title}
          {subtitle && isValidElement(subtitle) ? (
            subtitle
          ) : (
            <Text mt={2} fontSize='sm' color='gray.500'>
              {subtitle}
            </Text>
          )}
        </DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default Sidepeek
