"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROJECT_CANCELATION_REASONS = exports.REQUIRED_FIELDS = exports.IMMUTABLE_FIELDS = exports.PROJECT_TRACKING_STATUSES = exports.PROJECT_SUBSTATUSES = exports.INACTIVE_PROJECT_STATUSES = exports.ACTIVE_PROJECT_STATUSES = exports.ORDERED_PROJECT_STATUSES = exports.PROJECT_STATUSES = exports.PROJECT_TYPES = void 0;
exports.PROJECT_TYPES = {
    TURN: 'turn',
    TURN_OI: 'turn_oi',
    DISPOSITION: 'disposition',
    DISPOSITION_INSPECTION: 'disposition_inspection',
    DISPOSITION_OI: 'disposition_oi',
    REHAB: 'rehab'
};
exports.PROJECT_STATUSES = {
    PENDING_MOVE_OUT: 'pending_move_out',
    PENDING_MOVE_OUT_INSPECTION: 'pending_move_out_inspection',
    PENDING_SCOPE: 'pending_scope',
    PENDING_DISPATCH: 'pending_dispatch',
    PENDING_CONSTRUCTION_START: 'pending_construction_start',
    PENDING_CONSTRUCTION_COMPLETION: 'pending_construction_completion',
    PENDING_QUALITY_CONTROL: 'pending_quality_control',
    POST_QUALITY_CONTROL_TASKS: 'post_quality_control_tasks',
    COMPLETE: 'complete',
    CANCELED: 'canceled'
};
exports.ORDERED_PROJECT_STATUSES = [
    exports.PROJECT_STATUSES.PENDING_MOVE_OUT,
    exports.PROJECT_STATUSES.PENDING_MOVE_OUT_INSPECTION,
    exports.PROJECT_STATUSES.PENDING_SCOPE,
    exports.PROJECT_STATUSES.PENDING_DISPATCH,
    exports.PROJECT_STATUSES.PENDING_CONSTRUCTION_START,
    exports.PROJECT_STATUSES.PENDING_CONSTRUCTION_COMPLETION,
    exports.PROJECT_STATUSES.PENDING_QUALITY_CONTROL,
    exports.PROJECT_STATUSES.POST_QUALITY_CONTROL_TASKS,
    exports.PROJECT_STATUSES.COMPLETE,
    exports.PROJECT_STATUSES.CANCELED
];
exports.ACTIVE_PROJECT_STATUSES = [
    exports.PROJECT_STATUSES.PENDING_MOVE_OUT,
    exports.PROJECT_STATUSES.PENDING_MOVE_OUT_INSPECTION,
    exports.PROJECT_STATUSES.PENDING_SCOPE,
    exports.PROJECT_STATUSES.PENDING_DISPATCH,
    exports.PROJECT_STATUSES.PENDING_CONSTRUCTION_START,
    exports.PROJECT_STATUSES.PENDING_CONSTRUCTION_COMPLETION,
    exports.PROJECT_STATUSES.PENDING_QUALITY_CONTROL,
    exports.PROJECT_STATUSES.POST_QUALITY_CONTROL_TASKS
];
exports.INACTIVE_PROJECT_STATUSES = [exports.PROJECT_STATUSES.COMPLETE, exports.PROJECT_STATUSES.CANCELED];
exports.PROJECT_SUBSTATUSES = {
    NOT_STARTED: 'not_started',
    IN_PROGRESS: 'in_progress',
    BLOCKED: 'blocked',
    COMPLETE: 'complete'
};
exports.PROJECT_TRACKING_STATUSES = {
    ON_TRACK: 'on_track',
    ESCALATED: 'escalated'
};
exports.IMMUTABLE_FIELDS = ['id', 'portfolio_home_id', 'resource_id', 'created_at'];
exports.REQUIRED_FIELDS = ['type', 'status', 'portfolio_home_id'];
exports.PROJECT_CANCELATION_REASONS = {
    DEAL_TERMINATED: 'deal_terminated',
    DUPLICATE_PROJECT: 'duplicate_project',
    OTHER: 'other'
};
