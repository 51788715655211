import React, { useContext, useState } from 'react'
import get from 'lodash/get'
import { useSelector } from 'react-redux'
import { StoreState } from 'store'
import { useMutation, useQuery } from 'urql'

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Button,
  CircularProgress,
  Checkbox,
  Stack,
  Heading
} from '@chakra-ui/react'

import { capabilities, payments, rentals } from '@homevest/utils'

import { hasCapability } from 'lib/admin-perms'
import { formatMoney } from 'lib/numbers'
import { generatePaymentMethodDescription } from 'lib/payments'
import axios from 'lib/axios'
import {
  MoveInPaymentFragment,
  Rental_Applications,
  RentalApplicationCreditCardConfigurationDocument as CreditCardQuery,
  SetCreditCardMoveInPaymentFeeDisabledAtDocument as SaveCreditCardMoveInpaymentFeeDisabled,
  TenantsFragment
} from 'graphql/generated'
import { ContentSectionCard } from 'components/TailwindUIToolkit'

import { REQUEST_POLICY } from 'constants/urql'
import { MoveInPaymentContext, MoveInPaymentContextProvider } from './MoveInPaymentContext'

const { MOVE_IN_CHARGERS } = capabilities.CAPABILITY_TYPES

const { PAYMENT_METHOD_STATUSES } = payments
const {
  RENTAL_STATUS: { PENDING },
  RENTAL_PROGRAM_TYPES
} = rentals

type MoveInPaymentRentalFields = MoveInPaymentFragment & TenantsFragment

const MoveInPaymentDetails: React.FC<
  React.PropsWithChildren<{
    rental: MoveInPaymentRentalFields
    rentalApplication: Rental_Applications
  }>
> = ({ rental, rentalApplication }) => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isSaving, setIsSaving] = useState(false)
  const [cardFeeDisabledAt, setCardFeeDisabledAt] = useState<Date | null>(
    rentalApplication.credit_card_move_in_payment_fee_disabled_at
  )
  const admin = useSelector<StoreState, any>((state) => state.admin)
  const canChargeMoveInPayment = hasCapability(admin, MOVE_IN_CHARGERS)

  const defaultPaymentMethod = rental.user.payment_methods.find(
    (pm: any): boolean => pm.is_default && pm.status === PAYMENT_METHOD_STATUSES.ACTIVE
  )

  const [_, mutateCreditCardMoveInPaymentFeeDisabledAt] = useMutation(
    SaveCreditCardMoveInpaymentFeeDisabled
  )

  const saveCardFeeDisabledAt = async (d: Date | null): Promise<boolean> => {
    try {
      setIsSaving(true)
      await mutateCreditCardMoveInPaymentFeeDisabledAt({
        id: rental.created_by_rental_application_id,
        credit_card_move_in_payment_fee_disabled_at: d
      })
      return true
    } catch (err: any) {
      setErrorMessage(err.message)
      return false
    } finally {
      setIsSaving(false)
    }
  }

  const { moveInPayment, moveInPaymentError } = useContext(MoveInPaymentContext)

  if (moveInPaymentError) {
    return (
      <Alert status='error'>
        <AlertIcon />
        <AlertTitle>Error!</AlertTitle>
        <AlertDescription>
          An error occurred when loading Move In Payment information: {moveInPaymentError.message}
        </AlertDescription>
      </Alert>
    )
  }

  if (!moveInPayment) {
    return <CircularProgress isIndeterminate color='green.300' />
  }

  const handleSubmit = async () => {
    if (!get(moveInPayment, 'total_move_in_payment')) {
      setErrorMessage('There is no move in payment to charge!')
      return
    }

    if (
      window.confirm(
        `Are you sure you want to charge this customer $${formatMoney(
          moveInPayment.total_move_in_payment
        )}? Reversing is a pain in the ass ⚠.`
      )
    ) {
      try {
        setIsSaving(true)
        await axios.post(`/rentals/${rental.id}/move_in_payment`)
      } catch (err) {
        const error = err as any
        const detailMessage = get(error, 'response.data.message')
        setErrorMessage(detailMessage || error.message)
      } finally {
        setIsSaving(false)
      }
    }
  }

  const {
    type: moveInPaymentType,
    credit_card_fee: creditCardFee,
    prorated_rent: proratedRent,
    next_month_rent: nextMonthsRent,
    prorated_add_ons: proratedAddOns,
    next_month_add_ons: nextMonthAddOns,
    move_in_fees: moveInFees,
    total_move_in_payment: totalMoveInPayment,
    total_move_in_payment_with_fees: totalMoveInPaymentWithFees
  } = moveInPayment

  const primaryRentalUser = rental.rental_users.find((ru) => ru.role === 'primary')
  const showFeeDisabled =
    creditCardFee &&
    (rentalApplication.credit_card_enabled_at || primaryRentalUser?.credit_card_enabled_at)
  const ccFeeEnabledBy = rentalApplication?.credit_card_enabled_by_admin

  return (
    <div>
      <ContentSectionCard title='Charge Move In Payment' collapsable={true} padding>
        <Stack padding={3}>
          {errorMessage && (
            <Alert status='error'>
              <AlertIcon />
              <AlertTitle>Error!</AlertTitle>
              <AlertDescription>{errorMessage}</AlertDescription>
            </Alert>
          )}
          <Heading size='sm' textDecoration='underline'>
            Rent
          </Heading>
          <p>
            Prorated Rent: <b>${formatMoney(proratedRent)}</b>
          </p>
          {nextMonthsRent > 0 && (
            <p title='Added when a move-in date is after the 26th of the month'>
              Next Month's Rent: <b>${formatMoney(nextMonthsRent)}</b>
            </p>
          )}
          {(proratedAddOns.length > 0 || nextMonthAddOns.length > 0) && (
            <>
              <Heading size='sm' textDecoration='underline'>
                Add Ons
              </Heading>
              {proratedAddOns.length > 0 &&
                proratedAddOns.map((ao: any) => (
                  <p key={ao.liability_type_id} title={`Prorated ${ao.name}`}>
                    Prorated {ao.name}: <b>${formatMoney(ao.amount)}</b>
                  </p>
                ))}
              {nextMonthAddOns.length > 0 &&
                nextMonthAddOns.map((ao: any) => (
                  <p key={ao.liability_type_id} title={`Next Month's ${ao.name}`}>
                    Next Month's {ao.name}: <b>${formatMoney(ao.amount)}</b>
                  </p>
                ))}
            </>
          )}
          {moveInFees.length > 0 && (
            <>
              <Heading size='sm' textDecoration='underline'>
                Move-In Fees
              </Heading>
              {moveInFees.map((mif: any) => (
                <p key={mif.liability_type_id} title={`Move-In ${mif.name}`}>
                  Move-In {mif.name}: <b>${formatMoney(mif.amount)}</b>
                </p>
              ))}
            </>
          )}
          {moveInPaymentType === RENTAL_PROGRAM_TYPES.UPANDUP &&
            moveInPayment.remaining_initial_contribution > 0 && (
              <>
                <Heading size='sm' textDecoration='underline'>
                  Wallet Contribution
                </Heading>
                <p title='Initial wallet contribution'>
                  Remaining Initial Contribution:{' '}
                  <b>${formatMoney(moveInPayment.remaining_initial_contribution)}</b>
                </p>
              </>
            )}
          {moveInPaymentType === RENTAL_PROGRAM_TYPES.VANILLA &&
            moveInPayment.remaining_initial_deposit > 0 && (
              <>
                <Heading size='sm' textDecoration='underline'>
                  Security Deposit
                </Heading>
                <p title='Initial security deposit'>
                  Remaining Security Deposit:{' '}
                  <b>${formatMoney(moveInPayment.remaining_initial_deposit)}</b>
                </p>
              </>
            )}
          <p>
            <b>
              <u>Total Move In Payment</u>: ${formatMoney(totalMoveInPayment)}
            </b>
          </p>
          {creditCardFee > 0 && (
            <p title='Fee due to credit card payment method'>
              Credit Card Fee: <b>${formatMoney(creditCardFee)}</b>
            </p>
          )}
          {creditCardFee > 0 && (
            <p>
              Total Move In Payment With Fees: <b>${formatMoney(totalMoveInPaymentWithFees)}</b>
            </p>
          )}
          {showFeeDisabled && (
            <Checkbox
              isDisabled={!canChargeMoveInPayment || isSaving}
              isChecked={!!cardFeeDisabledAt}
              onChange={async (e: any): Promise<any> => {
                const d = e.target.checked ? new Date() : null
                setCardFeeDisabledAt(d)
                await saveCardFeeDisabledAt(d)
              }}
            >
              Disable credit card move in payment fee of {formatMoney(creditCardFee, 2, '$')}.{' '}
              {ccFeeEnabledBy && (
                <>
                  {ccFeeEnabledBy.first_name} {ccFeeEnabledBy.last_name} enabled credit card
                  payments for the rental application.`
                </>
              )}
            </Checkbox>
          )}

          <Button
            colorScheme='teal'
            onClick={handleSubmit}
            isDisabled={!canChargeMoveInPayment || isSaving || !totalMoveInPayment}
          >
            {generatePaymentMethodDescription(defaultPaymentMethod!, 'move in payment')}
          </Button>
          {!canChargeMoveInPayment && (
            <p>⚠️ You do not have permissions to charge the move in payment.</p>
          )}
        </Stack>
      </ContentSectionCard>
    </div>
  )
}

export const MoveInPayment: React.FC<
  React.PropsWithChildren<{
    rental: MoveInPaymentRentalFields
  }>
> = ({ rental }) => {
  const [{ data, error }] = useQuery({
    query: CreditCardQuery,
    variables: {
      id: rental.created_by_rental_application_id
    },
    requestPolicy: REQUEST_POLICY.CACHE_AND_NETWORK
  })

  if (rental.status !== PENDING) {
    return null
  }

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  return (
    <MoveInPaymentContextProvider rentalId={rental.id}>
      <React.Suspense fallback={<CircularProgress isIndeterminate color='teal.300' />}>
        <MoveInPaymentDetails rental={rental} rentalApplication={data.rental_application} />
      </React.Suspense>
    </MoveInPaymentContextProvider>
  )
}

export default MoveInPayment
