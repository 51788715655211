import { LEASE_END_REASONS, RENTAL_STATUS } from '@homevest/utils/rentals'
import { RentRollQuery } from 'graphql/generated'
import { parse } from 'date-fns'
export type RentRollStatus =
  | 'pending_move_in'
  | 'in_evictions'
  | 'ready_to_be_cashed_out'
  | 'on_notice'
  | 'standard'
  | 'completed'

export const getRentRollStatus = (rental: RentRollQuery['rent_roll'][number]): RentRollStatus => {
  if (
    rental.status === RENTAL_STATUS.PENDING ||
    (rental.status === RENTAL_STATUS.ACTIVE &&
      parse(rental.occupancy_date, 'yyyy-MM-dd', new Date()) > new Date())
  ) {
    return 'pending_move_in'
  }

  if (rental.rental?.evictions?.length) {
    return 'in_evictions'
  }

  if (rental.rental?.cashout) {
    return rental.rental.cashout.status === 'cashed_out' ? 'completed' : 'ready_to_be_cashed_out'
  }

  if (
    rental.move_out_date &&
    (!rental.lease_end_reason || rental.lease_end_reason !== LEASE_END_REASONS.NEVER_MOVED_IN)
  ) {
    const moveOutDate = parse(rental.move_out_date, 'yyyy-MM-dd', new Date())
    // If no cashout and move out date is in the past, we should mark as ready to cash out
    if (moveOutDate < new Date()) {
      // However very old rentals don't have cashouts, so have a date cutoff
      if (moveOutDate >= new Date('2023-07-01')) {
        return 'ready_to_be_cashed_out'
      } else {
        return 'completed'
      }
    }
    if (moveOutDate >= new Date()) {
      return 'on_notice'
    }
  }

  return rental.status &&
    [RENTAL_STATUS.CANCELED as string, RENTAL_STATUS.COMPLETE as string].includes(rental.status)
    ? 'completed'
    : 'standard'
}
