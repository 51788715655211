import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Button, Spinner, Center } from '@chakra-ui/react'
import type { ConstructionProjectApiResponse } from '@homevest/types/construction-projects'
import { OccupancyInspectionBulkApiResponse } from '@homevest/types/occupancy-inspections'

import axios from 'lib/axios'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { OccupancyInspectionGrid } from './components/OccupancyInspections/OccupancyInspectionGrid'
import CreateOccupancyInspectionSidepeek from './components/OccupancyInspections/CreateOccupancyInspectionSidepeek'

const OccupancyInspectionsCard = ({ project }: { project: ConstructionProjectApiResponse }) => {
  const [isOpen, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const projectId = project.id

  const {
    data,
    isFetching,
    isLoading,
    refetch: refetchOccupancyInspections
  } = useQuery({
    queryKey: ['getOccupancyInspections', projectId],
    queryFn: async () => {
      const response = await axios.get<OccupancyInspectionBulkApiResponse>(
        `/admin/construction_projects/${projectId}/occupancy_inspections`
      )
      return response.data?.data
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  if (isLoading || !data) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <>
      {isOpen && (
        <CreateOccupancyInspectionSidepeek
          project={project}
          isOpen={isOpen}
          onSubmit={refetchOccupancyInspections}
          onClose={onClose}
        />
      )}

      <ContentSectionCard
        title='Occupancy Inspections'
        action={
          <Button
            variant='outline'
            colorScheme='teal'
            onClick={(event) => {
              event.stopPropagation()
              onOpen()
            }}
          >
            Add +
          </Button>
        }
      >
        <OccupancyInspectionGrid
          oiData={data}
          isFetching={isFetching}
          refetchOccupancyInspections={refetchOccupancyInspections}
        />
      </ContentSectionCard>
    </>
  )
}

export default OccupancyInspectionsCard
