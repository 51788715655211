import React from 'react'
import { Box, Divider, useToast, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { MagnifyingGlassIcon, PencilIcon, DocumentIcon } from '@heroicons/react/20/solid'
import { OccupancyInspectionApiResponse } from '@homevest/types/occupancy-inspections'

import axios from 'lib/axios'
import { Sidepeek } from 'ui'
import { formatProjectTitle } from 'lib/construction-projects'
import {
  OccupancyInspectionFormData,
  validateOccupancyInspectionForm
} from 'lib/occupancy-inspections'
import OccupancyInspectionForm from './OccupancyInspectionForm'
import OccupancyInspectionDetails from './OccupancyInspectionDetails'
import InspectionDocuments from '../InspectionDocuments'

const OccupancyInspectionDetailsEditSidepeek = ({
  inspectionId,
  isOpen,
  onSubmit,
  onClose
}: {
  inspectionId: string
  isOpen: boolean
  onSubmit: () => void
  onClose: () => void
}) => {
  const toast = useToast()

  const {
    data,
    isFetching,
    refetch: refetchOccupancyInspection
  } = useQuery({
    queryKey: ['getOccupancyInspectionsById', inspectionId],
    queryFn: async () => {
      const response = await axios.get<OccupancyInspectionApiResponse>(
        `/admin/occupancy_inspections/${inspectionId}`
      )
      return response.data
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  const { mutate: editOccupancyInspection, isLoading } = useMutation({
    mutationFn: async (data: OccupancyInspectionFormData) => {
      if (!inspectionId) {
        return
      }
      return axios.put<OccupancyInspectionApiResponse>(
        `/admin/occupancy_inspections/${inspectionId}`,
        data
      )
    },
    onSuccess: () => {
      toast({
        title: 'Occupancy Inspection Edited',
        status: 'success',
        duration: 5000,
        position: 'bottom-right'
      })
      onSubmit()
      refetchOccupancyInspection()
    },
    onError: (error: any) => {
      toast({
        title: 'Error Editing Occupancy Inspection',
        description: error?.message || 'An unknown error occurred',
        status: 'error',
        duration: 5000,
        position: 'bottom-right'
      })
    }
  })

  if (isFetching || !data) {
    return
  }

  return (
    <Sidepeek
      isOpen={isOpen}
      onClose={onClose}
      title={'Occupancy Inspection'}
      subtitle={
        <div className='mt-2 text-base text-teal-600'>
          <strong>Project:</strong>{' '}
          {formatProjectTitle(data.project.type, data.project.address, data.project.start_date)}
        </div>
      }
      closeOnOverlayClick={false}
    >
      <Tabs isFitted variant='solid-rounded' colorScheme='teal'>
        <TabList mb='1em'>
          <Tab>{<MagnifyingGlassIcon className='mr-1 h-4 w-4' />} Details</Tab>
          <Tab>{<PencilIcon className='mr-1 h-4 w-4' />} Edit</Tab>
          <Tab>{<DocumentIcon className='mr-1 h-4 w-4' />} Documents</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <OccupancyInspectionDetails data={data}></OccupancyInspectionDetails>
          </TabPanel>
          <TabPanel>
            <Box mb={4}>
              <Divider borderColor='gray.300' />
            </Box>
            <OccupancyInspectionForm
              initialData={data}
              isLoading={isLoading}
              validator={validateOccupancyInspectionForm}
              onSubmit={(data) => editOccupancyInspection(data)}
            />
          </TabPanel>
          <TabPanel>
            <Divider borderColor='gray.300' mb={4} />
            <InspectionDocuments inspectionId={inspectionId} resourceType='occupancy_inspection' />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Sidepeek>
  )
}

export default OccupancyInspectionDetailsEditSidepeek
