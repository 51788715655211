import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { startCase } from 'lodash'
import { Select, Stack, Button, FormHelperText, Textarea } from '@chakra-ui/react'
import {
  OccupancyInspectionStatus,
  OccupancyInspectionResult,
  OccupancyInspectionBulkApiResponse
} from '@homevest/types/occupancy-inspections'
import {
  OCCUPANCY_INSPECTION_STATUSES,
  OCCUPANCY_INSPECTION_RESULTS
} from '@homevest/utils/occupancy-inspections'

import axios from 'lib/axios'
import { formatUTCDate, formatDateWithTime } from 'lib/date-time'
import { useContactsByProjectIdQuery } from 'graphql/generated'
import {
  OccupancyInspectionFormDataInput,
  OccupancyInspectionFormData,
  ValidationResponse
} from 'lib/occupancy-inspections'
import { BaseFormField, DateFormField, DateTimeFormField } from 'components/ChakraToolkit/FormField'
import { useUpupAdminsWithCapabilitiesByCapabilityTypeQuery } from 'graphql/generated'
import { CAPABILITY_TYPES } from '@homevest/utils/capabilities'

const OccupancyInspectionForm = ({
  initialData,
  isLoading,
  validator,
  onSubmit
}: {
  initialData: OccupancyInspectionFormDataInput
  isLoading: boolean
  validator: (data: OccupancyInspectionFormData) => ValidationResponse
  onSubmit: (data: OccupancyInspectionFormData) => void
}) => {
  const projectId = initialData.construction_project_id
  const [inspectionData, setInspectionData] = useState<OccupancyInspectionFormData>(() => ({
    construction_project_id: initialData.construction_project_id,
    previous_occupancy_inspection_id: initialData.previous_occupancy_inspection_id ?? null,
    primary_municipality_contact_id: initialData.primary_municipality_contact_id ?? null,
    status: initialData.status ?? null,
    result: initialData.result ?? null,
    in_person_application: initialData.in_person_application ?? null,
    assigned_admin_id: initialData.assigned_admin_id ?? null,
    application_submitted_date: initialData.application_submitted_date ?? null,
    municipality_contacted_date: initialData.municipality_contacted_date ?? null,
    payment_submitted_date: initialData.payment_submitted_date ?? null,
    payment_confirmed_date: initialData.payment_confirmed_date ?? null,
    inspection_at: initialData.inspection_at ?? null,
    certificate_effective_date: initialData.certificate_effective_date ?? null,
    certificate_expiration_date: initialData.certificate_expiration_date ?? null,
    occupant_signature_date: initialData.occupant_signature_date ?? null,
    notes: initialData.notes ?? null
  }))
  const [errors, setErrors] = useState<ValidationResponse['errors']>(
    {} as ValidationResponse['errors']
  )

  const [{ data: contactsData }] = useContactsByProjectIdQuery({
    variables: {
      projectId: projectId
    }
  })

  const [{ data: assignableAdminsData }] = useUpupAdminsWithCapabilitiesByCapabilityTypeQuery({
    variables: {
      capability_types: [CAPABILITY_TYPES.OCCUPANCY_INSPECTION_ASSIGNEE],
      status_types: ['active', 'inactive']
    }
  })

  const handleSubmit = () => {
    const { isValid, errors: currErrors } = validator(inspectionData)
    if (!isValid) {
      setErrors(currErrors)
      return
    }
    onSubmit(inspectionData)
  }

  const { data: previousOccupancyInspections } = useQuery({
    queryKey: ['getPreviousOccupancyInspections', projectId],
    queryFn: async () => {
      const response = await axios.get<OccupancyInspectionBulkApiResponse>(
        `/admin/construction_projects/${projectId}/previous_occupancy_inspections`
      )
      return response.data?.data
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })

  return (
    <Stack spacing={4}>
      <BaseFormField<OccupancyInspectionFormData>
        field='assigned_admin_id'
        label='Assigned To'
        isRequired={false}
        isDisabled={!assignableAdminsData || assignableAdminsData?.admins.length === 0}
        errors={errors}
      >
        {!assignableAdminsData || assignableAdminsData?.admins.length === 0 ? (
          <Select>
            <option value=''>No admins with correct capabilities</option>
          </Select>
        ) : (
          <>
            <Select
              value={inspectionData.assigned_admin_id ?? ''}
              onChange={(e) =>
                setInspectionData({
                  ...inspectionData,
                  assigned_admin_id: e.target.value || null
                })
              }
            >
              <option value=''>Select an admin</option>
              {assignableAdminsData.admins?.map((admin) => (
                <option key={admin.id} value={admin.id}>
                  {admin.first_name} {admin.last_name}
                </option>
              ))}
            </Select>
          </>
        )}
      </BaseFormField>
      <BaseFormField<OccupancyInspectionFormData>
        field='status'
        label='Status'
        isRequired
        errors={errors}
      >
        <Select
          value={inspectionData.status ?? ''}
          onChange={(e) =>
            setInspectionData({
              ...inspectionData,
              status: (e.target.value || null) as OccupancyInspectionStatus | null
            })
          }
        >
          <option value=''>Select a status</option>
          {Object.values(OCCUPANCY_INSPECTION_STATUSES).map((status) => (
            <option key={status} value={status}>
              {startCase(status)}
            </option>
          ))}
        </Select>
      </BaseFormField>
      <BaseFormField<OccupancyInspectionFormData>
        field='result'
        label='Result'
        isRequired={false}
        errors={errors}
      >
        <Select
          value={inspectionData.result ?? ''}
          onChange={(e) =>
            setInspectionData({
              ...inspectionData,
              result: (e.target.value || null) as OccupancyInspectionResult | null
            })
          }
        >
          <option value=''>Select a result</option>
          {Object.values(OCCUPANCY_INSPECTION_RESULTS).map((result) => (
            <option key={result} value={result}>
              {startCase(result)}
            </option>
          ))}
        </Select>
      </BaseFormField>
      <BaseFormField<OccupancyInspectionFormData>
        field='primary_municipality_contact_id'
        label='Primary Municipality Contact'
        isRequired={false}
        errors={errors}
      >
        <Select
          value={inspectionData.primary_municipality_contact_id ?? ''}
          onChange={(e) =>
            setInspectionData({
              ...inspectionData,
              primary_municipality_contact_id: e.target.value || null
            })
          }
        >
          <option value=''>Select a contact</option>
          {contactsData?.municipality_contacts.map((contact) => (
            <option key={contact.id} value={contact.id}>
              {contact.name}
            </option>
          ))}
        </Select>
        <FormHelperText>
          Not seeing the right contact? We are working on a way to add more.
        </FormHelperText>
      </BaseFormField>
      <BaseFormField<OccupancyInspectionFormData>
        field='in_person_application'
        label='In Person Application?'
        isRequired={false}
        errors={errors}
      >
        <Select
          value={
            inspectionData.in_person_application === null
              ? ''
              : String(inspectionData.in_person_application)
          }
          onChange={(e) =>
            setInspectionData({
              ...inspectionData,
              in_person_application:
                e.target.value === 'true' ? true : e.target.value === 'false' ? false : null
            })
          }
        >
          <option value=''>Select an option</option>
          <option value='true'>Yes</option>
          <option value='false'>No</option>
        </Select>
      </BaseFormField>
      <DateFormField<OccupancyInspectionFormData>
        field='application_submitted_date'
        label='Application Submitted Date'
        isRequired={false}
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <DateFormField<OccupancyInspectionFormData>
        field='municipality_contacted_date'
        label='Municipality Contacted Date'
        isRequired={false}
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <DateFormField<OccupancyInspectionFormData>
        field='payment_submitted_date'
        label='Payment Submitted Date'
        isRequired={false}
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <DateFormField<OccupancyInspectionFormData>
        field='payment_confirmed_date'
        label='Payment Confirmed Date'
        isRequired={false}
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <DateTimeFormField<OccupancyInspectionFormData>
        field='inspection_at'
        label='Inspection At'
        isRequired={false}
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <DateFormField<OccupancyInspectionFormData>
        field='certificate_effective_date'
        label='Certificate Effective Date'
        isRequired={false}
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <DateFormField<OccupancyInspectionFormData>
        field='certificate_expiration_date'
        label='Certificate Expiration Date'
        isRequired={false}
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <DateFormField<OccupancyInspectionFormData>
        field='occupant_signature_date'
        label='Occupant Signature Date'
        isRequired={false}
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <BaseFormField<OccupancyInspectionFormData>
        field='previous_occupancy_inspection_id'
        label='Previous Occupancy Inspection'
        isRequired={false}
        isDisabled={previousOccupancyInspections?.length === 0}
        errors={errors}
      >
        {previousOccupancyInspections?.length === 0 ? (
          <Select>
            <option value=''>No previous occupancy inspections</option>
          </Select>
        ) : (
          <>
            <FormHelperText>
              PROJECT TYPE - Project Start Date - OI Status - OI Inspection At
            </FormHelperText>
            <Select
              value={inspectionData.previous_occupancy_inspection_id ?? ''}
              onChange={(e) =>
                setInspectionData({
                  ...inspectionData,
                  previous_occupancy_inspection_id: e.target.value || null
                })
              }
              className='mt-2'
            >
              <option value=''>Select an occupancy inspection</option>
              {previousOccupancyInspections?.map((oi) => (
                <option key={oi.id} value={oi.id}>
                  {oi.project.type.toUpperCase()} -{' '}
                  {formatUTCDate(oi.project.start_date?.toString(), 'MM/dd/yy')} -{' '}
                  {startCase(oi.status)}
                  {oi.inspection_at ? ` - ${formatDateWithTime(oi.inspection_at?.toString())}` : ''}
                </option>
              ))}
            </Select>
          </>
        )}
      </BaseFormField>
      <BaseFormField<OccupancyInspectionFormData>
        field='notes'
        label='Notes'
        isRequired={false}
        errors={errors}
      >
        <Textarea
          value={inspectionData.notes ?? ''}
          onChange={(e) => setInspectionData({ ...inspectionData, notes: e.target.value || null })}
          placeholder='Enter any notes here...'
        />
      </BaseFormField>
      <Button
        onClick={() => handleSubmit()}
        colorScheme='teal'
        isLoading={isLoading}
        isDisabled={isLoading}
      >
        Submit
      </Button>
    </Stack>
  )
}

export default OccupancyInspectionForm
