import React, { useState, useEffect } from 'react'
import { Stack, Input, Button, Spinner, Center } from '@chakra-ui/react'
import { GridApi, ICellRendererParams } from 'ag-grid-community'
import { OccupancyInspectionBulkApiResponseItem } from '@homevest/types/occupancy-inspections'
import { OCCUPANCY_INSPECTION_STATUSES } from '@homevest/utils/occupancy-inspections'

import { BaseGrid } from 'components/TailwindUIToolkit'
import { occupancyInspectionColumns } from 'components/Construction/Projects/Details/Cards/components/OccupancyInspections/OccupancyInspectionGrid'
import OccupancyInspectionDetailsEditSidepeek from 'components/Construction/Projects/Details/Cards/components/OccupancyInspections/OccupancyInspectionDetailsEditSidepeek'

const OccupancyInspectionsGrid = ({
  data = [],
  isFetching,
  refetchOccupancyInspections,
  gridApi,
  setGridApi
}: {
  data?: OccupancyInspectionBulkApiResponseItem[]
  isFetching: boolean
  refetchOccupancyInspections: () => void
  statusesToShow: (typeof OCCUPANCY_INSPECTION_STATUSES)[keyof typeof OCCUPANCY_INSPECTION_STATUSES][]
  gridApi: GridApi | null
  setGridApi: (api: GridApi) => void
}) => {
  const [occupancyInspectionsSearch, setOccupancyInspectionsSearch] = useState('')

  const onGridReady = (params: { api: GridApi }) => {
    setGridApi(params.api)
  }

  useEffect(() => {
    gridApi?.setQuickFilter(occupancyInspectionsSearch)
    gridApi?.onFilterChanged()
  }, [gridApi, occupancyInspectionsSearch])

  const [isOpen, setOpen] = useState(false)
  const onOpen = () => setOpen(true)
  const onClose = () => setOpen(false)

  const [selectedInspectionId, setSelectedInspectionId] = useState<string | null>(null)

  const openDetailsSidepeek = (inspection?: OccupancyInspectionBulkApiResponseItem) => {
    setSelectedInspectionId(inspection?.id ?? null)
    onOpen()
  }

  const COLUMNS = occupancyInspectionColumns.filter(
    (column) => column.colId !== 'previous_occupancy_inspection_id'
  )

  return (
    <Stack gap={4}>
      <Input
        type='text'
        value={occupancyInspectionsSearch}
        onChange={(e) => setOccupancyInspectionsSearch(e.target.value)}
        placeholder='Search by project, address, etc...'
      />
      {isOpen && (
        <OccupancyInspectionDetailsEditSidepeek
          inspectionId={selectedInspectionId!}
          isOpen={isOpen}
          onSubmit={refetchOccupancyInspections}
          onClose={onClose}
        />
      )}
      <div className='h-[80vh] rounded border'>
        {isFetching ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <BaseGrid
            rowData={data}
            columns={[
              {
                colId: 'actions',
                headerName: '',
                width: 75,
                cellRenderer: (
                  params: ICellRendererParams<OccupancyInspectionBulkApiResponseItem>
                ) => (
                  <>
                    <Button
                      aria-label='View/Edit'
                      variant={'outline'}
                      colorScheme='teal'
                      size='xs'
                      fontSize='10px'
                      padding='3px 5px'
                      onClick={() => openDetailsSidepeek(params.data)}
                    >
                      View/Edit
                    </Button>
                  </>
                )
              },
              ...COLUMNS
            ]}
            onGridReady={onGridReady}
            defaultColDefOverrides={{
              wrapHeaderText: true,
              autoHeaderHeight: true
            }}
          />
        )}
      </div>
    </Stack>
  )
}

export default OccupancyInspectionsGrid
