import React from 'react'
import { Helmet } from 'react-helmet'
import { Box, Divider, Heading, Stack, Text } from '@chakra-ui/react'
import { UpcomingRenewalRentals } from '@homevest/types/renewals'
import RentalsToRenewTable from './UpcomingRenewalsTable'
import SelectedRentalView from '../../SelectedRentalView'
import axios from 'lib/axios'

async function fetchUpcomingRentals() {
  return await axios.get<UpcomingRenewalRentals>('/admin/rentals/upcoming_renewals')
}

const RenewalManagerUpcomingView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [selectedRental, setSelectedRental] = React.useState<UpcomingRenewalRentals[number]>()
  const [data, setData] = React.useState<UpcomingRenewalRentals>()
  const [loading, setLoading] = React.useState(false)

  const getData = () => {
    setLoading(true)
    fetchUpcomingRentals()
      .then((res) => {
        console.log(res.data)
        setData(res.data)
      })
      .finally(() => {
        setLoading(false)
        setSelectedRental(undefined)
      })
  }

  React.useEffect(getData, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <>
      <Helmet>
        <title>Renewal Manager</title>
      </Helmet>
      <Box w='100%' p={1} padding={3}>
        <Stack spacing={2}>
          <Heading as='h5' size='lg' width='100%'>
            Rentals to Renew
          </Heading>
          {!selectedRental && (
            <Text as='i'>Click on a row to enter renewal offers for a rental.</Text>
          )}
          <RentalsToRenewTable data={data || []} setSelectedRental={setSelectedRental} />
          <Divider />
          <SelectedRentalView onRenewalSubmit={getData} selectedRental={selectedRental} />
        </Stack>
      </Box>
    </>
  )
}

export default RenewalManagerUpcomingView
