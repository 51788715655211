import React, { useState } from 'react'

import { Sidepeek } from 'ui'
import { ContentSectionCard, ActionButton } from 'components/TailwindUIToolkit'
import Grid from 'components/TailwindUIToolkit/Grid/DocumentsGrid'
import { UploadDocumentPanel } from 'components/Documents/UploadDocumentPanel'
import { CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP } from 'lib/document-manager'
import {
  useOccupancyInspectionByConstructionProjectIdQuery,
  useBuyersInspectionByConstructionProjectIdQuery
} from 'graphql/generated'

const ProjectDocuments = ({ constructionProjectId }: { constructionProjectId: string }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const onClose = () => setDrawerOpen(false)
  const uploadButton = (
    <ActionButton onClick={() => setDrawerOpen(true)}>
      + Upload
      <Sidepeek isOpen={drawerOpen} onClose={onClose}>
        <UploadDocumentPanel
          resourceId={constructionProjectId}
          resourceType='construction_project'
          typeMap={CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP}
          setClosed={onClose}
        />
      </Sidepeek>
    </ActionButton>
  )

  const [{ data: occupancyInspectionsData }] = useOccupancyInspectionByConstructionProjectIdQuery({
    variables: { constructionProjectId }
  })
  const [{ data: buyersInspectionsData }] = useBuyersInspectionByConstructionProjectIdQuery({
    variables: { constructionProjectId }
  })
  const occupancyInspectionsIds =
    occupancyInspectionsData?.construction_occupancy_inspections.flatMap(
      (inspection) => inspection.id
    )
  const buyersInspectionsIds =
    buyersInspectionsData?.construction_projects[0]?.portfolio_home?.portfolio_home_dispositions.flatMap(
      (disposition) =>
        disposition.portfolio_home_disposition_bids.flatMap((bid) =>
          bid.buyers_inspections.flatMap((inspection) => inspection.id)
        )
    )
  const resourceIds = [
    constructionProjectId,
    ...(occupancyInspectionsIds ?? []),
    ...(buyersInspectionsIds ?? [])
  ]

  return (
    <ContentSectionCard title='Documents' staticSize action={uploadButton}>
      <Grid resourceIds={resourceIds} />
    </ContentSectionCard>
  )
}

export default ProjectDocuments
