import React, { useState } from 'react'

import { ActionButton } from 'components/TailwindUIToolkit'
import DocumentsGrid from 'components/TailwindUIToolkit/Grid/DocumentsGrid'
import { UploadDocumentPanel } from 'components/Documents/UploadDocumentPanel'
import { CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP } from 'lib/document-manager'

const InspectionDocuments = ({
  inspectionId,
  resourceType
}: {
  inspectionId: string
  resourceType: string
}) => {
  const [isUploading, setIsUploading] = useState(false)

  if (isUploading) {
    return (
      <UploadDocumentPanel
        resourceId={inspectionId}
        resourceType={resourceType}
        typeMap={CONSTRUCTION_PROJECT_DOCUMENT_TYPE_MAP}
        setClosed={() => setIsUploading(false)}
      />
    )
  }

  return (
    <>
      <ActionButton
        onClick={() => setIsUploading(true)}
        variant='outline'
        style={{ width: '100%', marginBottom: '1rem' }}
      >
        + Upload{' '}
      </ActionButton>
      <div className='h-[400px]'>
        <DocumentsGrid resourceIds={[inspectionId]} columnIdsToExclude={['resource_type']} />
      </div>
    </>
  )
}

export default InspectionDocuments
