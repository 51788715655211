import React, { useState } from 'react'
import { startCase } from 'lodash'
import { Select, Input, Stack, Button, Link, FormHelperText, Textarea } from '@chakra-ui/react'
import { BuyersInspectionStatus, CounterResponseType } from '@homevest/types/buyers-inspections'
import {
  BUYERS_INSPECTION_STATUSES,
  COUNTER_RESPONSE_TYPES
} from '@homevest/utils/buyers-inspections'

import { RETOOL_APP_URL, RETOOL_DISPOSITIONS_TOOL_APP_ID } from 'constants/urls'
import { useDispositionBidsByPortfolioHomeQuery } from 'graphql/generated'
import { BuyersInspectionFormData, ValidationResponse } from 'lib/buyers-inspections'
import { formatUTCDate } from 'lib/date-time'
import { formatMoney } from 'lib/numbers'
import { BaseFormField, DateTimeFormField } from 'components/ChakraToolkit/FormField'

const BuyersInspectionForm = ({
  initialData,
  isLoading,
  portfolioHomeId,
  validator,
  onSubmit
}: {
  initialData: BuyersInspectionFormData
  isLoading: boolean
  portfolioHomeId: string
  validator: (data: BuyersInspectionFormData) => ValidationResponse
  onSubmit: (data: BuyersInspectionFormData) => void
}) => {
  const [inspectionData, setInspectionData] = useState<BuyersInspectionFormData>(() => ({
    portfolio_home_disposition_bid_id: initialData.portfolio_home_disposition_bid_id ?? null,
    status: initialData.status ?? null,
    submitted_by_buyer_at: initialData.submitted_by_buyer_at ?? null,
    counter_deadline_date: initialData.counter_deadline_date ?? null,
    vendor_bids_sent_at: initialData.vendor_bids_sent_at ?? null,
    counter_submitted_at: initialData.counter_submitted_at ?? null,
    counter_response_type: initialData.counter_response_type ?? null,
    counter_response_received_at: initialData.counter_response_received_at ?? null,
    notes: initialData.notes ?? null
  }))

  const [errors, setErrors] = useState<ValidationResponse['errors']>(
    {} as ValidationResponse['errors']
  )
  const [{ data: bidsData }] = useDispositionBidsByPortfolioHomeQuery({
    variables: {
      portfolioHomeId: portfolioHomeId
    }
  })

  const handleSubmit = () => {
    const { isValid, errors: currErrors } = validator(inspectionData)

    if (!isValid) {
      setErrors(currErrors)
      return
    }

    onSubmit(inspectionData)
  }

  return (
    <Stack spacing={4}>
      <BaseFormField<BuyersInspectionFormData>
        field='portfolio_home_disposition_bid_id'
        label='Related Bid'
        isRequired
        errors={errors}
      >
        <Select
          onChange={(e) =>
            setInspectionData({
              ...inspectionData,
              portfolio_home_disposition_bid_id: e.target.value || null
            })
          }
          value={inspectionData.portfolio_home_disposition_bid_id ?? ''}
        >
          <option value='' disabled>
            Select a bid
          </option>
          {bidsData?.dispositions_portfolio_home_disposition_bids.map((bid) => (
            <option key={bid.id} value={bid.id}>
              {bid.portfolio_home_disposition_counterparty.name} -{' '}
              {formatUTCDate(bid.created_at, 'M/dd/yy')} - {formatMoney(bid.bid_price, 0, '$')}
            </option>
          ))}
        </Select>
        <FormHelperText>
          Not seeing the right bid? You can log a new bid in the{' '}
          <Link
            color='teal.500'
            isExternal
            href={`${RETOOL_APP_URL}${RETOOL_DISPOSITIONS_TOOL_APP_ID}`}
          >
            dispositions tool
          </Link>
        </FormHelperText>
      </BaseFormField>
      <BaseFormField<BuyersInspectionFormData>
        field='status'
        label='Status'
        isRequired
        errors={errors}
      >
        <Select
          value={inspectionData.status ?? ''}
          onChange={(e) =>
            setInspectionData({
              ...inspectionData,
              status: (e.target.value || null) as BuyersInspectionStatus | null
            })
          }
        >
          <option value=''>Select a status</option>
          {Object.values(BUYERS_INSPECTION_STATUSES).map((status) => (
            <option key={status} value={status}>
              {startCase(status)}
            </option>
          ))}
        </Select>
      </BaseFormField>
      <DateTimeFormField<BuyersInspectionFormData>
        field='submitted_by_buyer_at'
        label='Submitted by Buyer At'
        isRequired
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <BaseFormField<BuyersInspectionFormData>
        field='counter_deadline_date'
        label='Counter Deadline'
        isRequired
        errors={errors}
      >
        <Input
          value={formatUTCDate(inspectionData.counter_deadline_date)}
          onChange={(e) =>
            setInspectionData({
              ...inspectionData,
              counter_deadline_date: e.target.value ? new Date(e.target.value) : null
            })
          }
          type='date'
        />
      </BaseFormField>
      <DateTimeFormField<BuyersInspectionFormData>
        field='vendor_bids_sent_at'
        label='Vendor Bids Sent At'
        isRequired={false}
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <DateTimeFormField<BuyersInspectionFormData>
        field='counter_submitted_at'
        label='Counter Submitted At'
        isRequired={false}
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <BaseFormField<BuyersInspectionFormData>
        field='counter_response_type'
        label='Counter Response'
        isRequired={false}
        errors={errors}
      >
        <Select
          onChange={(e) =>
            setInspectionData({
              ...inspectionData,
              counter_response_type: (e.target.value || null) as CounterResponseType | null
            })
          }
          value={inspectionData.counter_response_type ?? ''}
        >
          <option value=''>Select a response type</option>
          {Object.values(COUNTER_RESPONSE_TYPES).map((responseType) => (
            <option key={responseType} value={responseType}>
              {startCase(responseType)}
            </option>
          ))}
        </Select>
      </BaseFormField>
      <DateTimeFormField<BuyersInspectionFormData>
        field='counter_response_received_at'
        label='Counter Response Received At'
        isRequired={false}
        errors={errors}
        data={inspectionData}
        setData={setInspectionData}
      />
      <BaseFormField<BuyersInspectionFormData>
        field='notes'
        label='Notes'
        isRequired={false}
        errors={errors}
      >
        <Textarea
          value={inspectionData.notes ?? ''}
          onChange={(e) => setInspectionData({ ...inspectionData, notes: e.target.value || null })}
          placeholder='Enter any notes here...'
        />
      </BaseFormField>
      <Button
        onClick={() => handleSubmit()}
        colorScheme='teal'
        isLoading={isLoading}
        isDisabled={isLoading}
      >
        Submit
      </Button>
    </Stack>
  )
}

export default BuyersInspectionForm
