import React from 'react'
import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { PROJECT_TYPES } from '@homevest/utils/construction-projects'
import type { ConstructionProjectApiResponse } from '@homevest/types/construction-projects'

import {
  NotesCard,
  TicketsCard,
  DocumentsCard,
  ScopeLineItemsCard,
  BuyersInspectionsCard,
  OccupancyInspectionsCard
} from 'components/Construction/Projects/Details/Cards'

const Content: React.FC<{ project: ConstructionProjectApiResponse }> = ({ project }) => (
  <Stack>
    <Tabs colorScheme='teal'>
      <TabList>
        <Tab>Tickets</Tab>
        <Tab>Scope</Tab>
        <Tab>Inspections</Tab>
        <Tab>Notes</Tab>
        <Tab>Documents</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <TicketsCard constructionProjectId={project.id} />
        </TabPanel>
        <TabPanel>
          <ScopeLineItemsCard constructionProjectId={project.id} />
        </TabPanel>
        <TabPanel>
          <div className='monitor:flex-row flex flex-col gap-3'>
            {[PROJECT_TYPES.TURN_OI, PROJECT_TYPES.DISPOSITION_OI].includes(
              project.type as any
            ) && <OccupancyInspectionsCard project={project} />}
            {[PROJECT_TYPES.DISPOSITION, PROJECT_TYPES.DISPOSITION_INSPECTION].includes(
              project.type as any
            ) && (
              <BuyersInspectionsCard
                constructionProjectId={project.id}
                portfolioHomeId={project.portfolio_home_id!}
              />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <NotesCard constructionProjectId={project.id} />
        </TabPanel>
        <TabPanel>
          <DocumentsCard constructionProjectId={project.id} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </Stack>
)

export default Content
