"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IMMUTABLE_FIELDS = exports.REQUIRED_FIELDS = exports.OCCUPANCY_INSPECTION_RESULTS = exports.OCCUPANCY_INSPECTION_STATUSES = void 0;
exports.OCCUPANCY_INSPECTION_STATUSES = {
    PENDING_CONSTRUCTION_COMPLETION: 'pending_construction_completion',
    PENDING_APPLICATION: 'pending_application',
    APPLICATION_IN_PROGRESS: 'application_in_progress',
    PENDING_SCHEDULING: 'pending_scheduling',
    SCHEDULED: 'scheduled',
    PENDING_OCCUPANT_SIGNATURE: 'pending_occupant_signature',
    COMPLETE: 'complete'
};
exports.OCCUPANCY_INSPECTION_RESULTS = {
    HARD_FAIL: 'hard_fail',
    PASS: 'pass',
    SOFT_FAIL: 'soft_fail'
};
exports.REQUIRED_FIELDS = ['construction_project_id', 'status'];
exports.IMMUTABLE_FIELDS = [
    'id',
    'construction_project_id',
    'municipality_occupancy_inspection_config_id',
    'created_at',
    'updated_at'
];
