import React, { useState, useContext } from 'react'
import { Text, Button, Flex, Spacer, VStack } from '@chakra-ui/react'
import { format, isAfter, subDays, max as maxDate } from 'date-fns'
import { rentals } from '@homevest/utils'

import CancelDoNotRenewModal from './CancelDoNotRenewModal'
import { RentalDetailsContext } from '../RentalDetailsContext'

const { DO_NOT_RENEW_DAYS_BEFORE_LEASE_END } = rentals

const DoNotRenewSetPanel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  let { rentalData } = useContext(RentalDetailsContext)

  const doNotRenewAdminName = `${rentalData?.do_not_renew_selected_by_admin?.first_name} ${rentalData?.do_not_renew_selected_by_admin?.last_name}`
  const doNotRenewSetDate = rentalData?.do_not_renew_selected_at
    ? format(new Date(rentalData?.do_not_renew_selected_at), 'M/d/yyyy')
    : ''
  const doNotRenewNoticeSentDate = rentalData?.do_not_renew_notice_sent_at
    ? format(new Date(rentalData?.do_not_renew_notice_sent_at), 'M/d/yyyy')
    : ''

  const leaseEndDate = rentalData?.rental_agreement_histories
    .sort((a, b) => (isAfter(new Date(a.ends_at), new Date(b.ends_at)) ? -1 : 1))
    .at(0)?.ends_at

  const doNotRenewScheduledDate = maxDate([
    subDays(new Date(leaseEndDate), DO_NOT_RENEW_DAYS_BEFORE_LEASE_END),
    new Date()
  ])

  return (
    <Flex>
      <VStack align={'flex-start'} spacing={7}>
        <Text>
          {doNotRenewAdminName} set <b>Do Not Renew</b> for this rental on {doNotRenewSetDate} for
          the following reason: "{rentalData?.do_not_renew_reason}"
        </Text>
        <Text>
          {doNotRenewNoticeSentDate ? (
            `A Do Not Renew notice was sent to the tenants on ${doNotRenewNoticeSentDate}, so you can no longer cancel the Do Not Renew status for this rental. Please contact Engineering if you have any issues.`
          ) : (
            <div>
              A Do Not Renew notice email is scheduled to go out for this rental{' '}
              <b>on {format(doNotRenewScheduledDate, 'M/dd/yyyy')} </b>. If you do not want the
              tenant to receive a do not renew notice, please cancel the Do Not Renew Status before
              then.
            </div>
          )}
        </Text>
      </VStack>
      <Spacer />
      <CancelDoNotRenewModal isOpen={isCancelModalOpen} close={() => setIsCancelModalOpen(false)} />
      {!doNotRenewNoticeSentDate && (
        <Button
          style={{
            whiteSpace: 'normal',
            wordWrap: 'break-word'
          }}
          padding={6}
          colorScheme='yellow'
          variant='outline'
          onClick={() => setIsCancelModalOpen(true)}
        >
          Cancel Do Not Renew
        </Button>
      )}
    </Flex>
  )
}

export default DoNotRenewSetPanel
